// importing theme variables
@import "../../../../styles/themes/default/variables";

app-login {
    display: block;
    height: 100%;
    background: left / cover no-repeat;

    @media screen and (min-width: #{$md}) {
        display: flex;
        min-height: 500px;
    }

    // page background image 
    // TODO: move bg image to body tag
    @media screen and (max-width: #{$sm}) {
        background-image: url(../../../../../public/assets/images/bg-img-xs.jpg);
    }
    @media screen and (min-width: #{$sm-min}) and (max-width: #{$md}) {
        background-image: url(../../../../../public/assets/images/bg-img-sm.jpg);
    }
    @media screen and (min-width: #{$md-min}) and (max-width: #{$lg}) {
        background-image: url(../../../../../public/assets/images/bg-img-md.jpg);
    }
    @media screen and (min-width: #{$lg-min}) and (max-width: #{$xl}) {
        background-image: url(../../../../../public/assets/images/bg-img-lg.jpg);
    }
    @media screen and (min-width: #{$xl-min}) and (max-width: #{$xxl}) {
        background-image: url(../../../../../public/assets/images/bg-img-xl.jpg);
    }
    @media screen and (min-width: #{$xxl-min}) {
        background-image: url(../../../../../public/assets/images/bg-img-xxl.jpg);
    }

    form {
        flex-grow: 1;
    }

    .login-page-wrapper {
        height: 100%;
        margin: 0;
    }

    .login-container-hero {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        position: relative;

        .login-logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            max-width: 400px;
            // background-color :rgba($neutral-0, 0.7);
            // border-radius: 5px;
            // padding-top: 1rem;

            #logo-redcurve {
                width: 13rem;
                background-color: $neutral-0;
                border-radius: 5px;
                padding: 1rem;
            }
            
            li {
                color: $neutral-0;
            }
        }
    }

    // login footers
    .login-footer-mobile,
    .login-footer-desktop {
        text-align: center;
        color: $neutral-0;
        font-size: 0.75rem;
        padding: 0.5rem 0.75rem;
        border-radius: 5px;
        background-color: rgba($red-100, 0.5);
        
        span {
            white-space: nowrap;
        }
    }

    .login-footer-mobile {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .login-footer-desktop {
        position: absolute;
        bottom: 0.25rem;
        height: 2rem;
    }

    .login-container-form {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: #{$md-min}) {
            padding: 3rem;
            background-color: rgba($neutral-0, 0.2);
        }
    }

    @media screen and (max-width: #{$md}) {
        .p-card {
            background-color: rgba($neutral-0, 0.75);
        }
    }

    .account-help a {
        font-size: 0.85rem;
        color: $neutral-80;
    }

    
}
