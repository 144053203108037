// making these fields take 100% width of the container
.p-dropdown {
    display: flex;
}

// centering dropdown chevron better
.p-dropdown-trigger-icon {
    display: flex;
    align-items: center;
    width: 0.875rem;
}

// adding missing disabled styling
.p-dropdown.p-disabled {
    background-color: $disabledBgColor;
    cursor: default;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
    border-radius: 5px;
    border: $inputBorder;
}