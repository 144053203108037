
// Left side nav
#sidenav {
    background-color: $neutral-0;
    min-width: $main-nav-width;
    overflow: hidden;
    padding: 0px 0px;
    margin: 0px 0px;
    column-gap: 2rem;

    rc-main-nav {
        padding-right: 1.5rem;
        overflow: visible;

        p-panelmenu {
            margin-top: 1.5rem;
            display: block;
        }
    }

    .p-panelmenu div, .p-panelmenu div:hover {
        background-color: $neutral-0;
    }


    .p-panelmenu-panel {
        margin-left: 0.5rem;
        padding-left: 0.125rem; 
        border:0px;
        background-color: $neutral-0;

        li {
            background-color: $neutral-0;
        }
    }

    .p-panelmenu-header {
        margin-bottom: 1.25rem;

        & + .p-toggleable-content {
            display: none;
            max-height: 0px;
            animation: .1s linear 1s slidein;
            opacity: 0;

            .p-panelmenu-content {
                margin-bottom: 1rem;
                padding: 0;
            }
        }

        &.p-highlight  {
            font-weight: 900;

            .section-icon {
                color: $neutral-100;
            }

            & + .p-toggleable-content {
                display: block;
                max-height: 1000px;
                margin-top: -0.5rem;
                animation: .1s linear 1s slidein;
                opacity: 1;
            }
        }
    }
    
    
    a.main-nav-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding-left: 1.25rem;
        text-decoration: none;
        color: $neutral-80;

        * {
            cursor: pointer;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: $red-60;

            .section-icon::before {
                color: $red-60;
            }
        }

        .section-icon {
            font-size: 1.25rem;
            color: $redwood-40;
            width: 25px;
        }

        .item-title {
            position: relative;
            padding-left: 1rem;
            display: flex;
            align-items: center;
            flex-grow: 4;

            &.active {
                font-weight: 900;
            }
        }
        
        .expand-chevron {
            font-size: 0.75rem;
            color: $redwood-60;
            margin-right: 20px;
        }
    }

    // submenu items
    .p-menuitem {
        overflow: visible;
        padding-left: 2rem;

        .main-nav-item {
            padding: 0.5rem 0 0.5rem 0.75rem;
            background-color: $neutral-0;
            border-left: 2px solid $redwood-10;
        }

        &:hover,
        &:has(div.active) {
            .main-nav-item {
                border-left: 2px solid $red-60;
                position: relative;
            }
        }
        
    }
}


@media screen and (min-width: #{$lg}) {
#sidenav app-app-nav, 
#sidenav .appBar {
        display: none;
    }
}

// Mobile view only
@media screen and (max-width: #{$lg}) {
    header app-app-nav {
        display: none;
        }
    #sidenav {
        column-gap: 10px;

        &.closed {
            display: none;
        }

        &.open {
            position: fixed;
            height: calc(99.99vh - $brand-bar-height-mobile);
            width: 100%;
            max-width: $main-nav-width-mobile;
            margin-top: $brand-bar-height-mobile;
            z-index: 1000;
            
            // scrim overlay for open menu
            & + main #paper::before {
                content: "";
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba($neutral-100, 0.5);
                z-index: 999;
            }

            .p-panelmenu {
                height: calc(99.9vh - 20rem);
                overflow: scroll;
                margin: 1.5rem 0 3rem;
            }
        }
    }
}
