
.p-stepper {
    
    .p-stepper-header {
        @media screen and (max-width: #{$sm}) {
            padding-left: 0; 
        }
    
        .p-stepper-action {
            margin-bottom: 0.25rem;

            .p-stepper-title {
                font-size: 1.5rem; // 24px
                margin-left: 0.75rem;
            } 
            
            .p-stepper-number {
                border-width: 1px;
            }
        }

        &.p-highlight {
            .p-stepper-title {
                font-weight: 700;
                color: $redwood-70;
            } 
            
            .p-stepper-number {
                background: $neutral-0;
                color: $redwood-70;
            }
        }

        &.p-disabled .p-stepper-action {
            cursor: default;
        }

        &:not(.p-disabled) .p-stepper-action {
            &:hover,
            &:active,
            &:focus {
                .p-stepper-title,
                .p-stepper-number {
                    color: $red-60;
                    cursor: pointer;
                }
            }
        }


    }

    &.p-stepper-vertical {
        margin-bottom: 2rem;
        
        .p-stepper-panel {
            .p-stepper-separator {
                width: 1px;
                margin-inline-start: 1.85rem;

                @media screen and (max-width: #{$sm}) {
                    margin-inline-start: 1rem;
                }
            }

            .p-stepper-content {
                padding-left: 1.75rem;
                padding-bottom: 1rem;

                @media screen and (max-width: #{$sm}) {
                    padding-left: 1rem;
                }

                .p-card {
                    margin-bottom: 0.75rem;
                }
            }
        }
    }
}

