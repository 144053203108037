html, body { 
    height: 100%;
    width: 100%;
    margin: 0; 
}

// Main page with left nav template layout
app-default {
    background-color: $redwood-20;
    background: $redwood-gradient-1;

    #page-header {
        height: $page-header-height;
        width: 100%;

        @media screen and (max-width: #{$lg}) {
            position: fixed;
            z-index: 1000;
            height: $brand-bar-height;
        }
    }
    
    #mainArea {
        display: flex;
        min-height: calc(99.99vh - $page-header-height);

        @media screen and (max-width: #{$lg}) {
            min-height: calc(99.99vh - $brand-bar-height);
        }
    }

    main {
        background-color: $redwood-20; 
        background: $redwood-gradient-1;
        width: calc(100% - $main-nav-width); 
    
        #paper {
            padding: 0 2rem 2rem 2rem;
            margin-top: 0;
            min-height: calc(99.99vh - $page-header-height - $page-footer-height);
            // BUG: next line is causing button menues misalignment
            // for what element do we need it?
            // position: relative;

            @media screen and (max-width: #{$lg}) {
                min-height: calc(99.99vh - $brand-bar-height - $page-footer-height);
            }

            @media screen and (max-width: #{$xs}) {
                padding: 0 1rem 1rem 1rem;
            }
        }
    }

    
    
    // footer
    #page-footer {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        background-color: $neutral-0;
        width: 100%;
        height: $page-footer-height;
        border-left: 1px solid $redwood-20;
    }
    #logo-redcurve {
        @extend .pl-3;
        height: 50px;
        width: auto;
        margin-right: $spacer*3;
    }
    #copyright {
        font-size: 13px;
        text-align: right;
        flex-grow: 1;
        
        span {
            white-space: nowrap;
            padding: 0 0.25rem;
        }
    }
    #action-top-button {
        margin: 0 $spacer*1.5;
    } 
    
    
    // Responsive - mobile view
    @media screen and (max-width: #{$lg}) {
        main {
            width: 100%;
            margin-top: $brand-bar-height-mobile;
        }

        #page-footer {
            flex-direction: column;
            height: auto;
            padding: 1rem;
        }

        #copyright {
            text-align: center;
            margin: 0.75rem;
        }

        #action-top {
            order: -1;
            margin-bottom: 1rem;
        }
    }
}

// No left nav pages like login and register
app-full-page {
    display: flex;
    height: 100%;
    background-color: $red-80;
    background: $redwood-gradient-3;

    #mainArea {
        width: 100%;
    }

    .layout-centered {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 3rem;  
        
        .p-card {
            border-radius: 5px;
            box-shadow: $overlayMenuShadow;
            width: calc(100vw - 60px);
            max-width: 360px;

            .p-card-content {
                padding: 2rem 0;
            }
        }
    }
}

// footer with action buttons at the bottom of the main area,
// for such pages as Add new Donor page
.page-footer {
    margin: -1rem -2rem -2rem -2rem;
    border-top: 2px solid $redwood-20;
    justify-items: flex-end;
}
