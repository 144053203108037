// simple card - white container with padding
// p-card {
//     width: 100%;
// }

.p-card {
    border-radius: 0;
    box-shadow: none;
    // margin-bottom: 1rem; 
}

.p-card-body {
    padding: 0 2rem;

    @media screen and (max-width: #{$sm}) {
        padding: 0 1rem;
    }
}

// for Donor and Donation cards
.card-style-donor .p-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    background: $redwood-gradient-2;

    p {
        color: $neutral-0;
        font-weight: 700;
        margin: 0;
    }

    // icon buttons for card headers
    button {
        color: $neutral-0;
        font-size: 1.125rem;
        width: $buttonIconOnlyWidth;
        height: $buttonIconOnlyHeight;
        padding: $buttonIconOnlyPadding; 
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $redwood-50;
        }

        &:focus,
        &:active {
            background-color: $redwood-60;
        }
    }

    + .p-card-body {
        padding: 0 1rem;
    }
}

// for account pages like register, forgot password
.card-style-account .p-card-header {
    text-align: center;
    padding: 1rem 0 0.5rem;
    border-bottom: 1px solid $red-40;

    #logo-redcurve {
        width: auto;
        height: 50px;
    }
}