// importing theme variables
@import "../../../../styles/themes/default/variables";

// narrow bar at the top of the page header that contains high-level product navigation (Vortex 3, Vortex Lite, APEX, Admin tools) as well as account icons


.appBar {

    &.apps-only,
    &:not(.account-only) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $app-bar-height;
        background-color: $red-100;
        padding: 0 2rem;
    }
    
    ul {
        display: flex;
        margin: 0;
        padding: 0;
        li {
            white-space: nowrap;
            list-style-type: none;
            padding: 0;
            height: $app-bar-height;
            &:not(:last-child) {
                margin-right: 0.25rem;
            }
            // a covers both .product-link and .app-bar-icon links
            a {
                display: flex;
                align-items: center;
                height: $app-bar-height;
                color: $neutral-0;  
                padding: 0 1rem;
            }
        }
    }

    .product-link {
        font-weight: 300;
        font-size: 1.1rem;
        text-transform: uppercase;
        text-decoration: none;
    }

    .icon {
        stroke: $neutral-0;
        fill: $neutral-0;
    }

    a.product-link,
    a.app-bar-icon {
        cursor: pointer;
        &.selected {
            background-color: rgba($neutral-0, 0.1);
            font-weight: 700;
        } 
        &:hover,
        &:active {
            background-color: rgba($neutral-0, 0.1);
            text-decoration: none;
        }
        &:focus,
        &:active {
            outline: 1px solid $neutral-0;
            outline-offset: -1px;
            text-decoration: none;
        }
    }

    &:not(.account-only) .app-bar-icon span {
        display: none;
    }

    // mobile view
    &.apps-only {
        padding: 0 1rem;
        justify-content: center;

        .product-link {
            font-size: 0.85rem;
            padding: 0 0.5rem !important;
        }
    }

    &.account-only {
        display: block;
        background-color: $redwood-5;
        border-top:1px solid $redwood-40;
        padding: 1rem;
        position: fixed;
        width: $main-nav-width-mobile;
        height: 7rem;
        bottom: 0;

        ul {
            display: block;
            margin: 0;
            padding: 0;

            li {
                white-space: nowrap;
                list-style-type: none;
                padding: 0;
                height: 2.5rem;

                a {
                    color: $textColor;
                    height: 2.5rem;

                    i {
                        color: $redwood-60;
                        font-size: 1.25rem;
                        width: 25px;
                    }

                    span {
                        padding-left: 0.5rem;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: #{$lg}) {
    header app-app-nav {
        display: none;
    }
}

@media screen and (min-width: #{$lg-min}) {
    rc-main-nav app-app-nav, 
    rc-main-nav .appBar {
        display: none;
    }
}