
p-dialog[styleclass*="p-dialog-static"] {
    .p-dialog-mask {
        justify-content: flex-end;
    }

    .p-resizable-handle {
        display: none;
    }

    .p-dialog-draggable .p-dialog-header {
        cursor: auto;
    }

    .p-dialog {
        width: 100%;
        max-height: 100%;
        height: 100vh;
        right: 0;
        left: auto !important;

        .p-dialog-header {
            flex-wrap: wrap;

            .modal-header {
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                flex-grow: 1;

                @media screen and (max-width: #{$xs}) {
                    & {
                        width: 100%;
                        margin-bottom: 1rem;
                        justify-content: center;
                    }
                }
            }

            .p-dialog-header-icon {
                box-shadow: none;
                border-radius: 5px;
                padding: 0;
                width: 2.5rem;
                height: 2.5rem;
            }
    
            .h4 {
                margin: 0;
            }

            .button-back {
                width: auto;
                height: 2.5rem;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    
        .p-dialog-content {
            padding: 2rem;
            background-color: $redwood-10;
        }
    
        .p-dialog-header,
        .p-dialog-footer {
            padding: 1rem 1.5rem;
            border-radius: 0;
        }

        .p-dialog-footer {
            box-shadow: 0px 2px 30px 10px rgba($redwood-80, 0.2);
            z-index: 2;
        }
    }

    

    // Sizing

    :not(.p-dialog-maximized) {
        &.p-dialog-lg {
            max-width: 1060px;
        }
    
        &.p-dialog-md {
            max-width: 720px;
        }
    
        &.p-dialog-sm {
            max-width: 360px;
        }
    }
    
    .p-dialog-maximized {
        max-width: 100%;
    }

    // faking dialog footer for actions from imported component
    .imported-dialog-footer {
        box-shadow: 0px 2px 30px 10px rgba($redwood-80, 0.2);
        z-index: 2;
        padding: 1rem 1.5rem;
        border-top: 1px solid $redwood-20;
        background: white;
        text-align: right;
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-left: -2rem;
    }

}

// Confirmation dialogs
.p-confirm-dialog {
    .p-dialog-header,
    .p-dialog-footer {
        border: 0;
    }

    .p-dialog-header {
        color: $red-60;
    }

    .p-dialog-content {
        padding: 0.5rem 1.5rem;
    }
}



