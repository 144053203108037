@import "./themes/default/_variables.scss"; 

@import "../../node_modules/primeicons/primeicons.css";

// @import "../../node_modules/primeflex/primeflex.scss";

// Importing only individual modules because not all of them are needed
// @import '../../node_modules/primeflex/core/_variables';
@import '../../node_modules/primeflex/core/_mixins';
@import '../../node_modules/primeflex/core/_grid';
// @import '../../node_modules/primeflex/core/_colors';
@import '../../node_modules/primeflex/core/_formlayout';
@import '../../node_modules/primeflex/core/_display';
@import '../../node_modules/primeflex/core/_typography';
@import '../../node_modules/primeflex/core/_flexbox';
@import '../../node_modules/primeflex/core/_gap';
@import '../../node_modules/primeflex/core/_spacing';
@import '../../node_modules/primeflex/core/_elevation';
@import '../../node_modules/primeflex/core/_border';
@import '../../node_modules/primeflex/core/_borderradius';
@import '../../node_modules/primeflex/core/_size';
@import '../../node_modules/primeflex/core/_position';
@import '../../node_modules/primeflex/core/_overflow';
@import '../../node_modules/primeflex/core/_zindex';
@import '../../node_modules/primeflex/core/_image';
@import '../../node_modules/primeflex/core/_userselect';
@import '../../node_modules/primeflex/core/_liststyle';
@import '../../node_modules/primeflex/core/_misc';
@import '../../node_modules/primeflex/core/_transition';
@import '../../node_modules/primeflex/core/_transform';
@import '../../node_modules/primeflex/core/_animation';
@import '../../node_modules/primeflex/core/_utils';


@import "./themes/default/theme.scss";

// keep for now, for testing
// @import "./themes/primeng-example/bootstrap4-light/purple/theme.scss"; 
