.tabbed-card {
    &.p-tabview {
        display: flex;
        flex-direction: row-reverse;

        @media screen and (max-width: #{$md}) {
            & {
                flex-direction: column-reverse;
            }
        }
    }
    // nav
    @media screen and (max-width: #{$md}) {
        .p-tabview-nav-content {
            margin: 0 1rem;
        }
    }

    .p-tabview-nav {
        border: 1px solid $redwood-30;
        border-radius: 0 0 5px 5px;
        border-top-width: 0;

        @media screen and (min-width: #{$md-min}) {
            display: block;
            width: 150px;
            margin: 1.5rem 0;
            border-radius: 0 5px 5px 0;
            border-left-width: 0;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }

        li {
            padding: 0.25rem 0.5rem;

            .p-tabview-nav-link {
                border: 0;
                padding: 0.5rem;
                color: $neutral-80;
                border-radius: 5px;
                font-weight: 400;
                font-size: 0.875rem;

                &:hover,
                &:active,
                &:focus {
                    background-color: $redwood-10;
                    text-decoration: none;
                }
            }

            &.p-highlight {
                .p-tabview-nav-link {
                    font-weight: 700;
                    background-color: $redwood-5;
                }
            }
        }
    }
    // @media screen and (max-width: #{$md}) {
    //     .p-tabview-nav {
    //         display: none;
    //     }
    // }
    // content
    .p-tabview-panels {
        background-color: $neutral-0;
        border: 3px solid $red-60;
        border-radius: 10px;
        width: 100%;
        padding: 1rem 0;
    }

    .content-type-icon {
        font-size: 3rem;
        color: $red-100;
        background-color: $redwood-10;
        padding: 10px;
        border-radius: 50%;

        @media screen and (max-width: #{$md}) {
            & {
                font-size: 2rem;
            }
        }

        @media screen and (max-width: #{$sm}) {
            & {
                display: none;
            }
        }
    }
}
