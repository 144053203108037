.input-group {
    input,
    button {
        height: 2.25rem;
    }
    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin: 0;
    }
}

.input-group button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none !important;
}