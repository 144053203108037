// Primary use, base button
.p-button {
    font-family: $fontFamily;
    font-weight: 700;
    box-shadow: $buttonShadow;
    display: inline-flex;
    align-items: center;

    &.p-button-outlined {
        background: $neutral-0;
    }

    &.p-button-no-shadow {
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
    }

    &.p-button-text {
        outline: none;
        box-shadow: none;
    }
}

p-button + p-button {
    margin-left: 0.5rem;
}

// Secondary use button
.p-button.p-button-secondary, .p-button-group.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    box-shadow: $secondaryButtonShadow;

    &.p-button-outlined {
        background-color: $neutral-0;
        color: $secondaryOutlinedButtonTextColor;
        border: $secondaryOutlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
            color: $secondaryOutlinedButtonTextColor;
            border: $secondaryOutlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
            color: $secondaryOutlinedButtonTextColor;
            border: $secondaryOutlinedButtonBorder;
        }
    }

    &.p-button-text {
        outline: none;
        box-shadow: none;
    }

    &.p-button-link {
        font-weight: 400;
    }
}

// Tertiary use button
.p-button.p-button-tertiary, .p-button-group.p-button-tertiary > .p-button, .p-splitbutton.p-button-tertiary > .p-button {
    color: $tertiaryButtonTextColor;
    background: $tertiaryButtonBg;
    border: $tertiaryButtonBorder;
    box-shadow: $tertiaryButtonShadow;

    &:not(:disabled):hover {
        background: $tertiaryButtonHoverBg;
        color: $tertiaryButtonTextHoverColor;
        border-color: $tertiaryButtonHoverBorderColor;
    }

    &:not(:disabled):focus {
        box-shadow: $tertiaryButtonFocusShadow;
    }

    &:not(:disabled):active {
        background: $tertiaryButtonActiveBg;
        color: $tertiaryButtonTextActiveColor;
        border-color: $tertiaryButtonActiveBorderColor;
    }
    
    &.p-button-outlined {
        background-color: $neutral-0;
        color: $tertiaryButtonTextColor;
        border: $tertiaryOutlinedButtonBorder;

        &:not(:disabled):hover {
            background: rgba($tertiaryButtonBg, 0.5);
            color: $tertiaryButtonTextColor;
            border: $tertiaryOutlinedButtonBorder;
        }

        &:not(:disabled):active {
            background: rgba($tertiaryButtonBg, 0.7);
            color: $tertiaryButtonTextColor;
            border: $tertiaryOutlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: $tertiaryButtonBg;
        border-color: transparent;
        outline: none;
        box-shadow: none;

        &:not(:disabled):hover {
            background: rgba($tertiaryButtonBg, $textButtonHoverBgOpacity);
            border-color: transparent;
            color: $tertiaryButtonBg;
        }

        &:not(:disabled):active {
            background: rgba($tertiaryButtonBg, $textButtonActiveBgOpacity);
            border-color: transparent;
            color: $tertiaryButtonBg;
        }
    }

    &:focus-visible {
        outline: $focusOutline;
        outline-offset: $focusOutlineOffset;
        box-shadow: $focusShadow;
    }
}

// UI status buttons
.p-button.p-button-info, .p-button-group.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button,
.p-button.p-button-success, .p-button-group.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button,
.p-button.p-button-warning, .p-button-group.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button,
.p-button.p-button-help, .p-button-group.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button,
.p-button.p-button-danger, .p-button-group.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    &.p-button-text {
        outline: none;
        box-shadow: none;
    }
}

// Simple link that should function as a button
.p-button.p-button-link {
    background-color: transparent;
    color: $textLinkColorDefault;
    border-color: transparent;
    outline: none;
    box-shadow: none;

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled):active {
        background: transparent;
        border-color: transparent;
        color: $textLinkColorHover;
        text-decoration: underline;
    }
}

// button cursor
button:not(:disabled), 
[type=button]:not(:disabled), 
[type=reset]:not(:disabled), 
[type=submit]:not(:disabled) {
    cursor: pointer;
}

// button group
.button-group {
    button, p-togglebutton {
        margin-right: $spacer;
    }
}

// button size overrides 
.p-button.p-button-sm {
    padding: 0.375rem 1rem;
}
// ===========================
// Disabled buttons

.p-button:disabled,
.p-button.p-disabled {
    color: $neutral-40;
    background-color: $neutral-10;
    border-color: $neutral-10;
    box-shadow: 0 2px 0 $neutral-20; 

    &.p-button-text,
    &.p-button-link {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none; 
    }
    

    &.p-button-outlined {
        color: $neutral-40;
        background-color: $neutral-0;
        border-color: $neutral-20;
    }
}

// ===========================
// Buttons with ICONS

// General properties
.p-button {
    .p-button-icon-left {
        margin-right: $inlineSpacing*1.5;
        margin-left: -0.25rem;
    }

    .p-button-icon-right {
        margin-left: $inlineSpacing*1.5;
        margin-right: -0.25rem;
        order: 1;
    }
}


// Add, plus icon - secondary outlined, such as "Add Another Address"
// Used in form grids to either open a modal or a dropdown with additional fields

// Clear, minus icon - secondary outlined, such as "Clear fields"
// Used in form grids to delete input from fields, for example, to remove address

// ===========================
// ICON ONLY action buttons

// General icon only button properties

.p-button {
    &.p-button-icon-only {
        font-size: 1.125rem;
        width: $buttonIconOnlyWidth;
        height: $buttonIconOnlyHeight;
        padding: $buttonIconOnlyPadding; 
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;

        // Used for delete button (trash icon)
        &.p-button-icon-red-light {
            color: $red-60;
            background-color: rgba($buttonBg, 0.08);

            &:not(:disabled):hover {
                background-color: rgba($buttonBg, 0.16);
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                background-color: rgba($buttonBg, 0.24);
            }
        }

        // Used for edit/settings button (gears icon)
        &.p-button-icon-teal-light {
            color: $teal-70;
            background: rgba($secondaryButtonBg, 0.08);

            &:not(:disabled):hover {
                background: rgba($secondaryButtonBg, 0.16);
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                background: rgba($secondaryButtonBg, 0.24);
            }
        }

        // Used for expense actions (my and RC approvals)
        &.p-button-icon-teal-medium {
            color: $teal-70;
            background: rgba($secondaryButtonBg, 0.3);

            &:not(:disabled):hover {
                background: rgba($secondaryButtonBg, 0.4);
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                background: rgba($secondaryButtonBg, 0.5);
            }
        }

        // Used for expense actions (my and RC approvals)
        &.p-button-icon-teal-dark {
            color: $neutral-0;
            background: $secondaryButtonBg;

            &:not(:disabled):hover {
                background: $secondaryButtonHoverBorderColor;
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                background: $secondaryButtonActiveBg;
            }
        }

        // Used for donation history button (magnifying glass with dollar sign icon)
        &.p-button-icon-redwood-light {
            color: $redwood-60;
            background-color: $redwood-5;

            &:not(:disabled):hover {
                background: rgba($redwood-20, 0.5);
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                background: rgba($redwood-20, 0.7);
            }
        }

        // Used for expense actions (my and RC approvals)
        &.p-button-icon-gray-light {
            color: $neutral-30;
            background-color: $neutral-5;

            &:not(:disabled):hover {
                background: rgba($neutral-20, 0.5);
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                background: rgba($neutral-20, 0.7);
            }
        }

        // Used for expense actions (my and RC approvals)
        &.p-button-icon-gray-dark {
            color: $neutral-0;
            background: $neutral-50;

            &:not(:disabled):hover {
                background: $neutral-60;
            }

            &:not(:disabled):active,
            &:not(:disabled):focus {
                background: $neutral-60;
            }
        }
    }
}

// TOGGLE BUTTON
.p-togglebutton.p-button {
    box-shadow: none;
    padding: 0.25rem 1rem;
}

// P-BUTTON styled like P-TOGGLEBUTTON
// used in the user invite page
p-button {
    // ENABLED state
    .p-button.rc-togglebutton {
        box-shadow: none;
        padding: 0.25rem 1rem;
        background: $teal-70;
        border: 0.25rem solid $teal-70;
        color: $neutral-0;

        .p-button-icon {
            &::before {
                color: $teal-30;
            }
        }

        &:hover {
            border-color: $teal-40;
        }
    }

    // DISABLED state
    &.p-disabled {
        opacity: 1;

        .p-button.rc-togglebutton {
            background: $neutral-5;
            border-color: $neutral-10;
            color: $neutral-70;

            .p-button-icon {
                &::before {
                    color: $red-60;
                }
            }

            &:hover {
                border-color: $teal-40;

                .p-button-icon {
                    &::before {
                        color: $teal-60;
                    }
                }
            }
        }
    }
}

