// Used in forms like Add Donor and Add Donation
.datatable-sm {
    border: 1px solid $neutral-20;

    .p-datatable-thead > tr > th {
        padding: 0.75rem 1rem;
    }

    .p-datatable-tbody > tr > td:nth-last-of-type(1) {
        text-align: right;
    }
}

// Main datatable style used for Donor, DOnation, etc. search results
.rc-datatable-outlined {
    &.p-datatable .p-datatable-header {
        padding: 0 0;
    }

    .p-paginator .p-dropdown .p-dropdown-label.p-inputtext {
        min-width: 3rem; // 3 text characters 
    }

    .p-datatable-wrapper {
        border-right: $tableHeaderCellBorder;
        border-left: $tableHeaderCellBorder;
    }

    .p-datatable-wrapper table {
        // min-width: 50rem; // why?

        th {
            border-top: $tableHeaderCellBorder;
            &:last-of-type {
                border-bottom: $tableHeaderCellBorder;
            }
        }

        th,
        td:nth-of-type(1) {
            border-right: $tableHeaderCellBorder;
        }

        th:nth-last-of-type(1),
        td:nth-last-of-type(1) {
            border-left: $tableHeaderCellBorder;
        }
    
        .p-column-filter-row {
            flex-grow: 1 0;
        }

        .p-column-filter-menu-button {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 5px;

            .p-icon {
                width: 0.85rem;
                height: 0.85rem;
            }
        }

        // hiding individual Clear filter buttons
        .p-column-filter-clear-button {
            display: none;
        }

        .p-hidden-space {
            display: none;
        }

        // making sure all inputs are wide enough
        .p-column-filter {
            .p-inputtext,
            .p-multiselect-label-container,
            .p-dropdown {
                min-width: 5rem; // fits ~7.5 characters 
            }
        }
    }
    
    .multi-select-columns {
        min-width: 200px;
        width: 700px;
    }

    &.p-datatable .text-center {
        text-align: center;
    }

    &.p-datatable .no-data-message {
        font-size: 1.2rem;  
        text-align: right;
        padding: 20px; 
        background-color: #f8f9fa;
    }

    // hiding button lables on XS for a more compact view
    @media screen and (max-width: #{$sm}) {
        .search-actions .p-button {
            padding: 0.5rem 0.75rem;
            
            .p-button-label {
                display: none;
            }

            .p-button-icon {
                margin: 0;
            }
        }
    }
    
}
