// importing theme variables
@import "../../../../styles/themes/default/variables";


.bottom-dashed-divider {
  background-size: 0, 0, 0, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-image: repeating-linear-gradient(0deg, $redwood-40, $redwood-40 5px, transparent 5px, transparent 10px, $redwood-40 10px), repeating-linear-gradient(90deg, $redwood-40, $redwood-40 5px, transparent 5px, transparent 10px, $redwood-40 10px), repeating-linear-gradient(180deg, $redwood-40, $redwood-40 5px, transparent 5px, transparent 10px, $redwood-40 10px), repeating-linear-gradient(270deg, $redwood-40, $redwood-40 5px, transparent 5px, transparent 10px, $redwood-40 10px);
}

.campaign-list {
  max-height: 32rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 1rem;

  p-checkbox {
    label {
      line-height: 1.25;
      padding: 0.125rem;
    }

    &:hover label,
    &:focus label,
    &:active label {
      color: $red-60;
      cursor: pointer;
    }
  }
}

.campaign-selected-title {
  font-size: 1.125rem;
  line-height: 1.75;
  color: $redwood-70;
}
.campaign-count {
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
  color: $red-60;
  margin-left: 1rem;
  z-index: 1;

  &::before {
    content: '';
    width: 2.5rem;
    height: 2.5rem;
    background-color: $ui-danger-10;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    z-index: -1;
  }
}

.product-name {
  font-size: 1.25rem;
  font-weight: 700;
  color: $red-60;
  line-height: 1.75;
  margin-left: 2rem;
}

.p-datatable-thead {
  th {
    text-align: center;

    span {
      display: block;
    }
  }
}
.p-datatable .p-datatable-tbody > tr > td:not(:nth-of-type(1)) {
  text-align: center;
}

.user-campaign-accordion {
  .p-accordion-tab {
    border: 1px solid $redwood-40;
    border-top-width: 2px;
    margin-bottom: 5px;
  }

  .p-accordion-header {

    &:not(.p-disabled).p-highlight .p-accordion-header-link {
      background-color: $redwood-10;
    }

    .p-accordion-header-link {
      color: $redwood-80;
      font-size: 1.25rem;
    }
  }

}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: $neutral-0;
}
