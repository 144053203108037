@mixin required-field {
    position: relative;
    margin-left: 0.5rem;

    &::before {
        content: "*";
        color: $ui-danger-60;
        font-size: 1.5rem;
        line-height: 0.75;
        position: absolute;
        top: 0.15rem;
        left: -1rem;
    }
}