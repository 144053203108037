// importing theme variables
@import "../../../../styles/themes/default/variables";

// Search, data filter and display table for Donors, Donations, Expenses, etc

$search-content-width: calc(100% - 400px);

#scrolling-search-filters {
    display: block;
    position: absolute;
    top: 5rem;
    padding: .2rem;
    background-color: $neutral-0;
    color: $inputListTextColor;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;
    width: 100%;

    .p-datatable {
        height: 140px;
        overflow:hidden;
    }
}

.top-paginator {
    padding-top: 18px;
}
.grey-text-button {
    color: $paginatorElementIconColor;
    &:hover, &:focus {
        background: $neutral-10;
    }
}

// FUTURE SCROLLING SEARCH MENU IMPLEMENTATION =========
// .search-actions  {
//     display: flex;
//     position: sticky;
//     top: 0px;
//     z-index: 99999;
//     background-color: $neutral-0;
//     //  max-width:  $search-content-width;
// }


// @media screen and (max-width: 991.9px) {

//     .search-actions {
//         top: 30px;
//         }

// }

p-table, app-search {
    // width: $search-content-width;
    // clear: both;
    .p-datatable {
        // width: $search-content-width;
        }
    // max-width: $search-content-width;
    .p-datatable-wrapper {  
        overflow: auto;
    }
}

#search-column-toggle {
    position: relative;
    margin-bottom: 1rem;

    .p-multiselect-label {
        padding-right: 2rem; 
    }

    // expand/minimize button in the column selector
    .btn-toggle-expand {
        position: absolute;
        top: 50%;
        right: 3rem;
        transform: translate3d(0, -50%, 0);
        width: 2rem;
        height: 2rem;
        z-index:100;
        background: transparent;
        border-color: transparent;
        border-radius: 5px;
        font-size: 1.125rem;

        &:hover {
            background-color: $redwood-5;
        }

        &:active,
        &:focus {
            background-color: $redwood-10;
        }

        @extend .fa-icon !optional;

        &::before {
            position: absolute;
            right: 50%;
            top: 50%;
            transform: translate3d(50%, -50%, 0);
            color: $redwood-60;
            content: '\f31e'; // default
        }

        &.icon-expand::before {
            content: '\f31e';
        }

        &.icon-minimize::before {
            content: '\f78c';
        }
    }
    
    p-multiSelect {
        min-width: 200px;
        width: 100%;

        .p-overlay .p-multiselect-items {
            columns: 3;
        }
    }
    
    .p-multiselect-label-container {
        max-height: 6.25rem; // fits up to 2 lines of chips
    }
    
    .p-multiselect-label {
        white-space: normal;
    }

    .p-multiselect-token {
        margin-bottom: $spacer*.25;
    }

    .p-multiselect-trigger {
        border-left: $tableHeaderCellBorder;
    }
    .expanded {
        min-height: 6rem;
    }
    .collapsed {
        min-height: 2rem;
    }
}

// data table header

.sortable-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .column-title {
        position: relative;
        padding-left: 1rem;

        &::before {
            content:"\e411"; // fa-grip-dots-vertical
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font: var(--fa-font-sharp-solid); 
            color: $neutral-40;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }
    }
}

p-sorticon {
    border-radius: 5px;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    margin-right: -0.25rem;

    &:hover {
        background-color: $neutral-0;
    }

    .p-icon {
        margin-left: 0;
        width: 0.85rem;
    }
}
.loader-wrapper {
    border: $tableHeaderCellBorder;
    border-top: 0px;
    height: 12rem; 
    background-color: $neutral-0; 
    z-index: 999; 
}
.disabled-row {
    background-color: $neutral-5; 
}
app-search {
    .p-paginator .p-dropdown .p-dropdown-label.p-inputtext {
        min-width: 3rem; // 3 text characters 
    }
    
    .p-datatable .p-datatable-header {
        padding: 0 0;
    }

    .p-datatable-wrapper {
        border-right: $tableHeaderCellBorder;
        border-left: $tableHeaderCellBorder;
    }

    .p-datatable-wrapper table {
        // min-width: 50rem; // why?

        th {
            border-top: $tableHeaderCellBorder;
            &:last-of-type {
                border-bottom: $tableHeaderCellBorder;
            }
        }

        th,
        td:nth-of-type(1) {
            border-right: $tableHeaderCellBorder;
        }

        th:nth-last-of-type(1),
        td:nth-last-of-type(1) {
            border-left: $tableHeaderCellBorder;
        }
    
        .p-column-filter-row {
            flex-grow: 1 0;
        }

        .p-column-filter-menu-button {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 5px;

            .p-icon {
                width: 0.85rem;
                height: 0.85rem;
            }
        }

        // hiding individual Clear filter buttons
        .p-column-filter-clear-button {
            display: none;
        }

        .p-hidden-space {
            display: none;
        }

        // making sure all inputs are wide enough
        .p-column-filter {
            .p-inputtext,
            .p-multiselect-label-container,
            .p-dropdown {
                min-width: 5rem; // fits ~7.5 characters 
            }
        }
    }
    
    .multi-select-columns {
        min-width: 200px;
        width: 700px;
    }

    .p-datatable .text-center {
        text-align: center;
    }

    .p-datatable .no-data-message {
        font-size: 1.2rem;  
        text-align: right;
        padding: 20px; 
        background-color: #f8f9fa;
    }

    // hiding button lables on XS for a more compact view
    @media screen and (max-width: #{$sm}) {
        .search-actions .p-button {
            padding: 0.5rem 0.75rem;
            
            .p-button-label {
                display: none;
            }

            .p-button-icon {
                margin: 0;
            }
        }
    }
    
}

