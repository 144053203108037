$prefix: '' !default;
$separator: '\\:' !default;

// Custom color scheme import
@import './variables/colors';

// Theme Specific Variables
$primaryColor: $red-60;
$primaryDarkColor: $red-80;
$primaryDarkerColor: $red-100;
$primaryTextColor: $neutral-0;
$neutral-0: white;

$actionLightColor: $teal-50;
$actionColor: $teal-60;
$actionDarkColor: $teal-70;
$actionDarkerColor: $teal-80;
$actionTextColor: $teal-60;

$fieldMargin: 1rem;
$fieldLabelMargin: .5rem;
$helperTextMargin: .25rem;

$gutter: .75rem;
$spacer: 1rem;
// spacing scales are ($spacer * $space-scale)
// $space-scales: 0, 0.25, 0.5, 1, 1.5, 2, 3, 4, 5 !default;
/// p-0 - 0 (0)
/// p-1 - 4px (0.25)
/// p-2 - 8px (0.5)
/// p-3 - 16px (1)
/// p-4 - 24px (1.5)
/// p-5 - 32px (2)
/// p-6 - 48px (3)
/// p-7 - 64px (4)
/// p-8 - 80px (5)

// Max-width breakpoints
// Use example: @media screen and (max-width: #{$lg}) {}
$xs: 374.9px;
$sm: 575.9px;
$md: 767.9px;
$lg: 991.9px;
$xl: 1199.9px;
$xxl: 1559.9px;

// Min-width breakpoints
// Use example: @media screen and (min-width: #{$lg-min}) {}
$xs-min: 375px;
$sm-min: 576px;
$md-min: 768px;
$lg-min: 992px;
$xl-min: 1200px;
$xxl-min: 1560px;

// $fieldMargin: 1rem;
// $fieldLabelMargin: .5rem;
// $helperTextMargin: .25rem;

$breakpoints: (
    'xs': $xs,
    'sm': $sm,
    'md': $md,
    'lg': $lg,
    'xl': $xl,
    'xxl': $xxl
);

// ================================
// layout variables
$app-bar-height: 3rem;
$brand-bar-height: 5rem;
$brand-bar-height-mobile: 4.5rem;
$page-header-height: $app-bar-height + $brand-bar-height;
$page-footer-height: 4rem;
$breadcrumb-height: 3rem;
$main-nav-width: 18rem;
$main-nav-width-mobile: 23.5rem;

// ================================
// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

// ================================
// Variables for global/custom components
@import './variables/global/_breadcrumbs';


// TODO: review which variables we need to keep in :root
:root {
    font-family: "Lato",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --text-color:#{$neutral-80};
    --text-color-secondary:#{$redwood-80};
    --primary-color:#{$red-60};
    --primary-color-text:#ffffff;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    // --focus-ring: #{$focusShadow};
    color-scheme: light;
}
