// importing theme variables
@import "../../../../styles/themes/default/variables";

// dcs = donor call sheets

.dcs-accordion {
    
    .p-accordion-header {
        border-bottom: 1px solid $redwood-20;
    }

    a.p-accordion-header-link {
        color: $red-60;
        font-size: 1.125rem;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            background-color: $redwood-10;

            .p-accordion-header-text {
                color: $red-80;
            }
        }
    }

    .p-accordion-header-text {
        width: 100%;
        white-space: nowrap;
    }
        
    .btn-complete-wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin: -1rem -1rem -1rem 2rem;
    }

    .btn-complete {
        padding: 1.25rem 1rem 1.25rem 2.75rem;
        appearance: none;
        border: 0;
        border-left: 1px solid $neutral-0;
        background-color: transparent;
        position: relative;

        &::before,
        &::after {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translate3d(0, -50%, 0);
        }

        &::before {
            font: var(--fa-font-sharp-light);
            content: '\f0c8';
            font-size: 1.25rem;
            color: $redwood-80;
            z-index: 1;
        }

        &::after {
            font: var(--fa-font-sharp-solid);
            content: '\f0c8';
            font-size: 1.25rem;
            color: $neutral-0;
            z-index: 0;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $redwood-20;
        }
    }

    .p-accordion-content {
        background: $neutral-0;
        box-shadow: rgba(0, 0, 0, 0.35) 0px -20px 36px -38px inset;

        .grid {
            margin-top: -1rem;
            margin-bottom: -1rem;
        }
    }

    p-accordiontab {
        &.state-complete {
            .p-accordion-header-link {
                background-color: $redwood-20;
            }

            .p-accordion-header-text,
            .p-accordion-toggle-icon {
                color: $redwood-70;
            }

            .btn-complete {
                &::before {
                    font: var(--fa-font-sharp-solid);
                    content: '\f14a';
                    font-size: 1.25rem;
                }
            }
            
        }
    }

    .dcs-donor-info {
        .donor-id {
            margin-right: 1rem;
        }

        .donor-occupation {
            margin-right: 0.5rem;
        }

        .donor-occupation,
        .donor-employer {
            font-weight: 100;
            font-size: 1.125rem;
        }

        .donor-occupation + .donor-employer {
            &::before {
                content: "at";
                margin-right: 0.5rem;
            }
        } 

        .donor-contact-info {
            display: flex;
            flex-wrap: wrap;

            span {
                white-space: nowrap;
                // flex-grow: 1;
                // flex-basis: 0;
                margin-bottom: 0.5rem;
                margin-right: 1rem;
            }
        }
    }

    .dcs-call-actions {
        border-top: 1px solid $redwood-20;

        @media screen and (min-width: #{$lg-min}) {
            border-top: 0;
            border-left: 1px solid $redwood-20;
        }
        
    }
}
