// importing theme variables
@import "../../../../styles/themes/default/variables";

.p-button.table-action-btn {
  margin: 0.125rem;
}

.add-donor-datatable {
  .same-household-yes {
    color: $redwood-80;
    font-size: 1.25rem;
  }

  .same-household-no {
    color: $neutral-30;
    font-size: 1.25rem;
  }
}

.p-autocomplete {
  display: flex;
}

// variable border between form and table
.section-divider {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: $redwood-5;

    width: calc(100% + 3rem);
    height: 4px;
    left: -1.5rem;
    top: -1rem;

    @media screen and (min-width: #{$xl-min}) {
        width: 4px;
        height: calc(100% + 2rem);
        left: -1rem;
        top: -1rem;
    }
  }
}
  
