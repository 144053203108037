// importing theme variables
@import "../../../../styles/themes/default/variables";

rc-campaign-switcher {
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;

    .selected-campaign-container {
        display: flex;
        justify-content: flex-end;
        padding-right: 0.5rem;
    }

    .selected-campaign-title {
        color: $neutral-60;
        margin-bottom: 0;
    }

    .selected-campaign-text {
        display: inline-block;
        font-weight: 300;
        font-size: 1.125rem;
        color: $redwood-90;

        &::after {
            display: inline-block;
            padding-left: 0.5rem;
            content: "\f08d";
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font: var(--fa-font-sharp-solid);
            font-size: 1rem;
            color: $redwood-60;
        }

        header#page-header & {
            text-align: right;
        }
    }

    .p-sidebar {
        width: 100%;
        max-width: 1060px;
    }

    .p-sidebar-header {
        padding-left: 2.5rem;
    }

    .p-sidebar-content {
        padding: 2rem;
        background-color: $redwood-5;
    }

    ul.campaign-switcher-grid,
    ul.recent-campaigns {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline-block;
            padding: 0;
            margin-bottom: 0.5rem;
            padding-right: 1rem;

            a {
                display: inline-block;
                position: relative;
                font-size: 1rem;
                padding: 0.25rem 0.5rem;
                color: $textColor;
                cursor: pointer;

                &:not(.inactive):hover,
                &:not(.inactive):active,
                &:not(.inactive):focus {
                    text-decoration: underline;
                    color: $red-60;

                    &:not(.inactive):not(.selected)::before {
                        display: inline-block;
                        position: absolute;
                        left: -1rem;
                        margin-top: 0.125rem;
                        content: "\f08d";
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        font: var(--fa-font-sharp-light);
                    }
                }

                &.selected {
                    font-weight: 700;
                    color: $red-80;

                    &::before {
                        display: inline-block;
                        position: absolute;
                        left: -1rem;
                        margin-top: 0.125rem;
                        content: "\f08d";
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        font: var(--fa-font-sharp-solid);
                    }
                }

                &.inactive{
                    color: $neutral-30;
                    &:hover {
                        cursor: default;
                    }
                }
            }
            
        }
    }

    .p-input-icon {
        z-index: 100;
    }

    ul.recent-campaigns {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }
}

#sidenav app-app-nav + rc-campaign-switcher {
    .selected-campaign-container {
        justify-content: center;
        background-color: $redwood-5;
        border-bottom:1px solid $redwood-40;
        padding: 0.5rem 1rem;
    }

    @media screen and (min-width: #{$lg-min}) {
        display: none;
    }
}

// Hide navigation actions on the interstatial page
.actions-hidden {
    #mobile-nav-actions,
    .back-button-div.mobile-view {
        display: none !important;
    }

    #brandBar {
        justify-content: center !important;
    }
}