.p-checkbox-box {
    box-shadow: $inputBoxShadow;
}

// adding missing disabled styling
.p-checkbox-box.p-disabled {
    background-color: $disabledBgColor;
}

// override for primeflex theme _formlayout.scss
.field > p-radiobutton + label {
    display: inline-block;
    font-weight: 400;
}

.field-checkbox > .field-label {
    display: block;
    width: 100%;
    font-weight: 700;
}

.disabled {
    cursor: default;

    .label {
        color: $neutral-40;
    }
}