/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: .5rem 1.25rem;

// ------------ Icon buttons -----------

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 2.25rem;

/// Height of a button having an icon with no label
/// @group button
$buttonIconOnlyHeight: 2.25rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: 0;

// ------- Primary/base button --------

/// Background of a button
/// @group button
$buttonBg: $primaryColor;

/// Text color of a button
/// @group button
$buttonTextColor: $primaryTextColor;

/// Border of a button
/// @group button
$buttonBorder: 1px solid $primaryColor;

/// Box shadow of a button 
/// @group button
$buttonShadow: 0 2px 0 $primaryDarkerColor;

/// HOVER ======
/// Background of a button in hover state
/// @group button
$buttonHoverBg: $primaryDarkColor;

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: $primaryTextColor;

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: $primaryDarkColor;

/// ACTIVE ======
/// Background of a button in pressed state
/// @group button
$buttonActiveBg: $primaryDarkerColor;

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: $primaryTextColor;

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: $primaryDarkerColor;

/// FOCUS ======
/// Box shadow of a button in focused state
/// @group button
// $buttonFocusShadow: 0 0 0 0.2rem lighten($secondaryButtonBg, 35%);
$buttonFocusShadow: $buttonShadow;

/// ------- other generic button properties ---------

/// Shadow of a raised button
/// @group button
$raisedButtonShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 50%;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity: 0.08;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity: .16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder:1px solid $primaryColor;

/// PLAIN gray button ======
/// Text color of a plain text button
/// @group button
$plainButtonTextColor:#6c757d;

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor:#e9ecef;

/// Background color of a plain text button in active state
/// @group button
$plainButtonActiveBgColor:#dee2e6;

// ------- Secondary buttons --------

/// Background of a secondary button
/// @group button
$secondaryButtonBg: $teal-60;

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: $neutral-0;

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: 1px solid $secondaryButtonBg;

/// Box shadow of a secondary button 
/// @group button
$secondaryButtonShadow: 0 2px 0 $teal-80;

/// OUTLINED ======
/// Border style of a secondary outlined button
/// @group button
$secondaryOutlinedButtonBorder: 1px solid $secondaryButtonBg;

/// Text color of a secondary outlined button
/// @group button
$secondaryOutlinedButtonTextColor: $teal-70;

/// HOVER ======
/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: $teal-70;

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: $neutral-0;

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: $teal-70;

/// ACTIVE ======
/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: $teal-80;

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: $neutral-0;

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: $teal-80;

/// FOCUS ======
/// Box shadow of a secondary button in focused state
/// @group button
// $secondaryButtonFocusShadow: 0 0 0 0.2rem lighten($secondaryButtonBg, 35%);
$secondaryButtonFocusShadow: $secondaryButtonShadow;

// ------- Tertiary buttons --------

/// Background of a tertiary button
/// @group button
$tertiaryButtonBg: $redwood-10;

/// Text color of a tertiary button
/// @group button
$tertiaryButtonTextColor: $redwood-60;

/// Box shadow color of a tertiary button
/// @group button
$tertiaryButtonShadowColor: $redwood-50;

/// Border of a tertiary button
/// @group button
$tertiaryButtonBorder: 1px solid $tertiaryButtonBg;

/// Box shadow of a tertiary button 
/// @group button
$tertiaryButtonShadow: 0 2px 0 $tertiaryButtonShadowColor;

/// OUTLINED ======
/// Border style of a tertiary outlined button
/// @group button
$tertiaryOutlinedButtonBorder: 1px solid $redwood-40;

/// HOVER ======
/// Background of a tertiary button in hover state
/// @group button
$tertiaryButtonHoverBg: $redwood-20;

/// Text color of a tertiary button in hover state
/// @group button
$tertiaryButtonTextHoverColor: $redwood-80;

/// Border color of a tertiary button in hover state
/// @group button
$tertiaryButtonHoverBorderColor: $tertiaryButtonHoverBg;

/// ACTIVE ======
/// Background of a tertiary button in pressed state
/// @group button
$tertiaryButtonActiveBg: $redwood-30;

/// Text color of a tertiary button in pressed state
/// @group button
$tertiaryButtonTextActiveColor: $redwood-90;

/// Border color of a tertiary button in pressed state
/// @group button
$tertiaryButtonActiveBorderColor: $redwood-40;

/// FOCUS ======
/// Box shadow of a tertiary button in focused state
/// @group button
// $tertiaryButtonFocusShadow: 0 0 0 0.2rem lighten($tertiaryButtonTextColor, 35%);
$tertiaryButtonFocusShadow: $tertiaryButtonShadow;

// ------- UI status buttons --------

/// Background of an info button
/// @group button
$infoButtonBg: #03A9F4;

/// Text color of an info button
/// @group button
$infoButtonTextColor: $neutral-0;

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid #03A9F4;

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: #039BE5;

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: $neutral-0;

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: #039BE5;

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: #0288D1;

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: $neutral-0;

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor: #0288D1;

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%);

/// Background of a success button
/// @group button
$successButtonBg: #4CAF50;

/// Text color of a success button
/// @group button
$successButtonTextColor: $neutral-0;

/// Border of a success button
/// @group button
$successButtonBorder: 1px solid #4CAF50;

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: #43A047;

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: $neutral-0;

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: #43A047;

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: #388E3C;

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: $neutral-0;

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: #388E3C;

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: 0 0 0 0.2rem lighten($successButtonBg, 35%);

/// Background of a warning button
/// @group button
$warningButtonBg: #FFC107;

/// Text color of a warning button
/// @group button
$warningButtonTextColor: $textColor;

/// Border of a warning button
/// @group button
$warningButtonBorder: 1px solid #FFC107;

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: #FFB300;

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: $textColor;

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: #FFB300;

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: #FFA000;

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: $textColor;

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: #FFA000;

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: 0 0 0 0.2rem lighten($warningButtonBg, 35%);

/// Background of a help button
/// @group button
$helpButtonBg:#9C27B0;

/// Text color of a help button
/// @group button
$helpButtonTextColor:$neutral-0;

/// Border of a help button
/// @group button
$helpButtonBorder:1px solid #9C27B0;

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg:#8E24AA;

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor:$neutral-0;

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor:#8E24AA;

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg:#7B1FA2;

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor:$neutral-0;

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor:#7B1FA2;

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow:0 0 0 0.2rem lighten($infoButtonBg, 35%);

/// Background of a danger button
/// @group button
$dangerButtonBg: #f44336;

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: $neutral-0;

/// Border of a danger button
/// @group button
$dangerButtonBorder: 1px solid #f44336;

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: #e53935;

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: $neutral-0;

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: #e53935;

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: #d32f2f;

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: $neutral-0;

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: #d32f2f;

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: 0 0 0 0.2rem lighten($dangerButtonBg, 35%);


/// ------------- Link button ----------------
/// 
/// Text color of a link button
/// @group button
$linkButtonColor:$textLinkColorDefault;

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor:$textLinkColorHover;

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration:underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: 0 0 0 0.2rem lighten($linkButtonColor, 35%);


/// ---------- Toggle Button --------------
/// 
/// Background of a toggle button
/// @group button
$toggleButtonBg: $neutral-5;

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 4px solid $neutral-10;

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: $textColor;

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: $red-60;

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: $neutral-5;

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: $teal-30;

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: $textColor;

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: $teal-80;

/// SELECTED STATE
/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: $teal-80;

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: $teal-80;

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: $neutral-0;

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: $teal-50;

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: $teal-80;

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: $teal-50;

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: $neutral-0;

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: $teal-50;

/// -------- Floating action button ---------------
/// 
/// Width of a floating action button on speed dial
/// @group button
$speedDialButtonWidth: 4rem;

/// Height of a floating action button on speed dial
/// @group button
$speedDialButtonHeight: 4rem;

/// Font Size of a floating action button on speed dial
/// @group button
$speedDialButtonIconFontSize: 1.3rem;

/// Width of any action button on speed dial
/// @group button
$speedDialActionWidth: 3rem;

/// Height of any action button on speed dial
/// @group button
$speedDialActionHeight: 3rem;

/// Background color of any action button on speed dial
/// @group button
$speedDialActionBg: #495057;

/// Background color of any action button on speed dial in hover state
/// @group button
$speedDialActionHoverBg: #343a40;

/// Color of any action button on speed dial
/// @group button
$speedDialActionTextColor: #fff;

/// Color of any action button on speed dial in hover state
/// @group button
$speedDialActionTextHoverColor: #fff;
