
// Neutral - white, grey, black
$neutral-0: #FFFFFF;
$neutral-5: #F2F2F2;
$neutral-10: #E5E5E5;
$neutral-20: #CCCCCC;
$neutral-30: #B3B3B3;
$neutral-40: #999999;
$neutral-50: #808080;
$neutral-60: #666666;
$neutral-70: #4D4D4D;
$neutral-80: #333333;
$neutral-90: #1A1A1A;
$neutral-100: #000000;

// Reds - RC brand and some derivatives to use sparingly for most important elements
$red-40: #D44335;
$red-60: #B13529; /* RC brand red */
$red-80: #8C261D;
$red-100: #681A13;

// Redwood - warm grey/brown, dominant UI color
$redwood-5: #F7F3F2;
$redwood-10: #EDE5E3;
$redwood-20: #E0D1CE;
$redwood-30: #D6C4C1;
$redwood-40: #BDA7A2;
$redwood-50: #A38B86;
$redwood-60: #876E69;
$redwood-70: #6B524D;
$redwood-80: #523C37;
$redwood-90: #382724;
$redwood-100: #1F1513;

// Gradients
$redwood-gradient-1: linear-gradient(90deg, rgba($redwood-30, 1) 0%, rgba($redwood-20, 1) 100%); // used for default page bg (app-default)
$redwood-gradient-2: linear-gradient(90deg, rgba($redwood-70, 1) 0%, rgba($redwood-50, 1) 100%); // used for card header bg
$redwood-gradient-3: linear-gradient(135deg, rgba(#6f0900, 1) 0%, rgba(#bd574e, 1) 100%); // used for login page bg (app-full-page)

// Teal - used exclusively for action items like buttons and links
$teal-5: #EFF6F7;
$teal-10: #DFF0F1;
$teal-20: #C3E7EB;
$teal-30: #9FD9E0;
$teal-40: #78C7D0;
$teal-50: #51B4BF;
$teal-60: #30939E;
$teal-70: #1A7580;
$teal-80: #0C565E;
$teal-90: #043A40;
$teal-100: #012225;

// UI colors - used exclusively for alerts, status messages and pills
// Danger - red
$ui-danger-10: #F7DFE5;
$ui-danger-20: #F2B7C6;
$ui-danger-40: #DA728C;
$ui-danger-60: #CB4566;
$ui-danger-80: #B72247;
$ui-danger-100: #990026;
// Warming - muddy orange
$ui-warning-10: #FFE9D9;
$ui-warning-20: #F2C9AA;
$ui-warning-40: #EDAA7E;
$ui-warning-60: #DE8348;
$ui-warning-80: #C05E1F;
$ui-warning-100: #993C00;
// Success - green
$ui-success-10: #DFF2E4;
$ui-success-20: #B2E7C0;
$ui-success-40: #84D48E;
$ui-success-60: #59B264;
$ui-success-80: #3A9045;
$ui-success-100: #1B6425;
// Info - blue-grey
$ui-info-10: #E1E7EE;
$ui-info-20: #BDCBDD;
$ui-info-40: #9CB1CB;
$ui-info-60: #7D98B8;
$ui-info-80: #5E7A9C;
$ui-info-100: #3A506B;

// Color map for generating classes
$theme-colors: (
    "neutral-0": #FFFFFF,
    "neutral-5": #F2F2F2,
    "neutral-10": #E5E5E5,
    "neutral-20": #CCCCCC,
    "neutral-30": #B3B3B3,
    "neutral-40": #999999,
    "neutral-50": #808080,
    "neutral-60": #666666,
    "neutral-70": #4D4D4D,
    "neutral-80": #333333,
    "neutral-90": #1A1A1A,
    "neutral-100": #000000,
    "red-40": #D44335,
    "red-60": #B13529,
    "red-80": #8C261D,
    "red-100": #681A13,
    "redwood-5": #F7F3F2,
    "redwood-10": #EDE5E3,
    "redwood-20": #E0D1CE,
    "redwood-30": #D6C4C1,
    "redwood-40": #BDA7A2,
    "redwood-50": #A38B86,
    "redwood-60": #876E69,
    "redwood-70": #6B524D,
    "redwood-80": #523C37,
    "redwood-90": #382724,
    "redwood-100": #1F1513,
    "teal-5": #EFF6F7,
    "teal-10": #DFF0F1,
    "teal-20": #C3E7EB,
    "teal-30": #9FD9E0,
    "teal-40": #78C7D0,
    "teal-50": #51B4BF,
    "teal-60": #30939E,
    "teal-70": #1A7580,
    "teal-80": #0C565E,
    "teal-90": #043A40,
    "teal-100": #012225,
    "ui-danger-10": #F7DFE5, 
    "ui-danger-20": #F2B7C6,
    "ui-danger-40": #DA728C,
    "ui-danger-60": #CB4566,
    "ui-danger-80": #B72247,
    "ui-danger-100": #990026,
    "ui-warning-10": #FFE9D9,
    "ui-warning-20": #F2C9AA,
    "ui-warning-40": #EDAA7E,
    "ui-warning-60": #DE8348,
    "ui-warning-80": #C05E1F,
    "ui-warning-100": #993C00,
    "ui-success-10": #DFF2E4,
    "ui-success-20": #B2E7C0,
    "ui-success-40": #84D48E,
    "ui-success-60": #59B264,
    "ui-success-80": #3A9045,
    "ui-success-100": #1B6425,
    "ui-info-10": #E1E7EE,
    "ui-info-20": #BDCBDD,
    "ui-info-40": #9CB1CB,
    "ui-info-60": #7D98B8,
    "ui-info-80": #5E7A9C,
    "ui-info-100": #3A506B
)