// for seach form filter fields
.p-column-filter .p-inputtext {
    min-width: 0.5rem;
}

.p-inputtext {
    width: 100%;
}

// Asterisk for all required form elements
label.required {
    @include required-field;
}

// right-positioned icons
.p-input-icon-right > .p-icon-wrapper {
    position: absolute;
    top: 0.5rem;
}

// disabled
.p-inputtext:disabled {
    background-color: $neutral-5;
}

.disabled {
    .field-label {
        color: $neutral-40;
    }
}

.p-calendar {
    max-width: unset;
    width: 100%;
}

// special quickfind field in the body content
.input-quickfind {
    font-size: 1.125rem;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid $redwood-30;
    padding: 0.65rem;
    padding-left: 1rem;
    position: relative;
    max-width: 25rem;

    p-inputicon + & {
        padding-left: 2rem;
    }
}

.p-button.btn-quickfind {
    padding: 0 1.5rem;
    color: $redwood-50;
    background-color: $neutral-0;
    border: 0;
    border-bottom: 2px solid $redwood-30;
    border-radius: 0;
    height: unset;

    &:hover,
    &:focus,
    &:active {
        color: $redwood-80;
        background-color: $redwood-10;
    }
}

.p-input-icon {
    color: $redwood-40;
    z-index: 100;
}


// looks like a regular text, without field border visible
.uneditable {
    .p-inputtext {
        border: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.field  small.error,
.field  .error small,
.field .p-error {
    color: $ui-danger-60;
    font-size: 0.8rem;
    display: block; // otherwise margin/padding doesn't work
}

// override for primeflex theme _formlayout.scss
.field label {
    display: block;
    font-weight: 700;
}

.field p-radiobutton + label {
    font-weight: 400;
}

// missing styles for inputgroup
.p-inputgroup {
    display: flex;
    align-items: stretch;
    width: 100%
}

.p-input-icon-left,
.p-input-icon-right {
    position: relative;
    display: inline-block
}

.p-input-icon-left>i,
.p-input-icon-left>.p-icon-wrapper,
.p-input-icon-right>i, 
.p-input-icon-right>.p-icon-wrapper {
    position: absolute;
    top: 50%;
    margin-top: -.5rem
}

// autocomplete and dropdown highlight
.p-autocomplete-item.p-highlight,
.p-dropdown-item.p-highlight {
    background-color: $teal-60;
}

// Global quicksearch in the header
.global-quicksearch {

    .p-inputgroup {
        @media screen and (max-width: #{$lg}) {
            justify-content: center;
            margin: auto 0.5rem;
        }

        & * {
            border-color: $redwood-40;
        }
            
        .p-dropdown.p-inputwrapper {
            border-radius: 5px 0 0 5px;
            height: 2.25rem;
        }

        .p-dropdown-items-wrapper {
            max-height: unset !important;
        }

        .p-inputtext {
            width: auto;
            border-left-width: 0;
            border-right-width: 0;
            height: 2.25rem;
        }
        .global-quicksearch-input {
            width: 50vw;
            max-width: 30rem;
        }

        .quicksearch-button {
            padding: 0 1rem;
            color: $redwood-50;
            background-color: $neutral-0;
            border-left-width: 0;

            &.button-search {
                border-right-width: 0;
            }

            &:hover,
            &:focus,
            &:active {
                color: $redwood-80;
                background-color: $redwood-5;
            }

            @media screen and (min-width: #{$lg-min}) {
                &.button-close {
                    display: none;
                }

                &.button-search {
                    border-right-width: 1px;
                }
            }
        }
    }
}

.field .p-checkbox-label {
    font-weight: 400;
}

// Lock labels are used on Add Donation
// but moving these here in case we need to use them elsewhere
label {
    .lock-icon {
        display: inline-block;
        margin-left: 0.25rem;
        padding: 0.25rem;
        border-radius: 5px;
        width: 26px;

        &::before {
            display: inline-block;
        }
    
        &.fa-lock-open {
            &:hover,
            &:active,
            &:focus {
                background-color: $redwood-10;
    
                &::before {
                    color: $red-60;
                }
            }
        } 
    
        &.fa-lock {
            color: $neutral-0;
            background-color: $red-60;
            cursor: pointer;

            &::before {
                // compensating for difference in icon widths
                margin-left: 2px; 
            }
    
            &:hover,
            &:active,
            &:focus {
                background-color: $red-80;
            }
        }
    }

    &:has(.lock-icon) {
        margin-bottom: 0.25rem;
    }
}
