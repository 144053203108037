// --------------------------------
// text color name styles
// i.e. .redwood-60
@mixin color-variant($parent, $color) {
    #{$parent} {
            color: $color !important; 
    }
}

@each $color, $value in $theme-colors {
    @include color-variant(".#{$color}", $value);
}

.white {
    color: #fff !important;
}

.base-color {
    color: #{$primaryTextColor} !important;
}

.transparent {
    color: transparent !important;
}

// --------------------------------
// background color name styles
// i.e. .bg-redwood-60
@mixin bg-variant($parent, $color) {
    #{$parent} {
            background-color: $color !important;
    }
}

@each $color, $value in $theme-colors {
    @include bg-variant(".bg-#{$color}", $value);
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

// Accesibility
.p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Dividers
.divider-dashed {
    color: transparent;
    border-top: 2px solid $neutral-20;
    border-style: dashed;
}

// cursor for action items
.cursor {
    cursor: pointer;
}

// size utilities
.full-width {
    width: 100%;
    clear: right;
}
.full-height {
    height: 100%;
    min-height: 20px;
}

// p-link - adding missing styles
.p-link {
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

// text color assignments
.text-danger {
    color: $ui-danger-80;
}

.text-warning {
    color: $ui-warning-80;
}

.text-success {
    color: $ui-success-80;
}

.text-info {
    color: $ui-info-80;
}


// loader/spinner 
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 1000;
}

.loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 20rem;
    height: 20rem;
    color: grey;
    position: relative;
}

.loader:before,
.loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
}

.loader:after {
    color: $primaryColor;
    transform: rotateY(70deg);
    animation-delay: .4s;
}

.medium .spinner .loader {
    width: 10rem !important;
    height: 10rem !important;
}


.small .spinner .loader {
    width: 5rem !important;
    height: 5rem !important;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin {
    0%,
    100% {
        box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
        box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
    }
}