// importing theme variables
@import "../../../../styles/themes/default/variables";

#nav-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $breadcrumb-height;

    app-breadcrumb {
        display: block;
        height: $breadcrumb-height;
    }
}

// Responsive
@media screen and (max-width: #{$lg}) {
    #nav-actions {
        display: none; 
    }
}

// Overrides for default breadcrumb component
.breadcrumb {
    .p-breadcrumb {
        // padding: 0px 0px 0px calc($spacer * 2);
        border-radius: 0px 0px 0px 0px;
    }
}
.p-breadcrumb a span.text-primary, .p-menuitem-separator chevronrighticon {
    color: $redwood-60 !important;
    fill: $redwood-60 !important;
    stroke: $redwood-60 !important;

    .p-icon {
        width: 0.8rem;
        height: 0.8rem;
    }
    
}
span.text-primary {
    cursor: pointer;
}

.p-breadcrumb-home {
    color: $red-80;
}

