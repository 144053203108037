// adding missing radiobutton styling
.p-radiobutton {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: bottom;
    position: relative
}

.p-radiobutton-box {
    display: flex;
    justify-content: center;
    align-items: center
}

.p-radiobutton-icon {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateZ(0) scale(.1);
    border-radius: 50%;
    visibility: hidden
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
    transform: translateZ(0) scale(1);
    visibility: visible
}

p-radiobutton {
    display: inline-flex;
    vertical-align: bottom;
    align-items: center
}

.p-radiobutton-label {
    line-height: 1
}

// adding shadow for more definition
.p-radiobutton-box {
    box-shadow: $inputBoxShadow;
}

// adding missing disabled styling
.p-radiobutton.p-radiobutton-disabled .p-radiobutton-box {
    background-color: $disabledBgColor;
    border: $inputBorder;
    cursor: default;
}
.p-radiobutton.p-radiobutton-disabled .p-radiobutton-box .p-radiobutton-icon {
    background-color: $neutral-40;
}

