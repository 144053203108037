// making these fields take 100% width of the container
.p-multiselect {
    display: flex;
}

// centering dropdown chevron better
.p-multiselect-trigger-icon,
.p-multiselect-close-icon {
    display: flex;
    align-items: center;
    width: 0.875rem;
}

// adding missing disabled styling
.p-multiselect.p-disabled {
    background-color: $disabledBgColor;
    cursor: default;
}

// Chips
.p-multiselect {
    &.p-multiselect-chip {
        .p-multiselect-token .p-icon-wrapper {
            display: flex;
            padding: 0 0.25rem;
            margin-left: 0.35rem;
        }

        .p-multiselect-token-icon {
            margin: 0;
        }
    }
}