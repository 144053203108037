// importing theme variables
@import "../../../../styles/themes/default/variables";

// styling for styleguide layout only

app-styleguide {
    display: block;
    // height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $redwood-20;
    background: $redwood-gradient-1;
}

#styleguide {
    background-color: $redwood-5;
    padding: 2rem;
    margin: 0 2rem;
    height: 100%;

    .sg-h1 {
        color: $red-60;
    }

    .p-tabview .p-tabview-panels {
        background: transparent;
        padding: 2rem 0;
    }

    .p-tabview-nav-content {
        border: 1px solid $neutral-20;
        padding-bottom: .125rem;
    }

    ul.p-tabview-nav {
        flex-wrap: wrap;
        border: 0;

        li {
            margin: .125rem;
        }


        .p-tabview-nav-link {
            border: 0;
            border-radius: 0;

            &:hover,
            &:focus,
            &:active {
                background-color: $teal-5;
            }

            &[aria-selected="true"] {
                background-color: $teal-10;
                color: $teal-80;
            }
            
        }
    }

    // code
    code {
        color: $neutral-60;
        background-color: rgba(antiquewhite, 0.6);
    }

    pre {
        position: relative;
        background: transparent;
        margin: 0.5rem 0 1rem;
        padding: 0;
        max-height: 20rem;
        overflow: auto;

        code {
            background-color: $teal-100;
            border-left: 1rem solid transparent;
            box-shadow: none;
            margin: 0;
            font-size: 14px;
            border-radius: 10px;
            color: #fff;
            max-height: inherit;
            height: inherit;
            padding: 1rem;
            display: block;
            overflow: auto;
        }
    }
}

