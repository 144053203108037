.p-tag {
    // background: $badgeBg;
    // color: $badgeTextColor;
    // font-size: $badgeFontSize;
    // font-weight: $badgeFontWeight;
    // padding: $tagPadding;
    // border-radius: $borderRadius;

    padding: 0.125rem 0.65rem;
    white-space: nowrap;
    
    &.p-tag-rounded {
        border-radius: 0.75rem;
    }

    &.p-tag-success {
        background-color: $successMessageBg;
        color: $successMessageTextColor;
    }

    &.p-tag-info {
        background-color: $infoMessageBg;
        color: $infoMessageTextColor;
    }

    &.p-tag-warning {
        background-color: $warningMessageBg;
        color: $warningMessageTextColor;
    }

    &.p-tag-danger {
        background-color: $errorMessageBg;
        color: $errorMessageTextColor;
    }
}