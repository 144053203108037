body {
    color: $textColor;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: 700;
    margin-top: 0;
}

h1, .h1 {
    font-size: 1.75rem;
    color: $neutral-100;
    margin-bottom: 1.5rem;
}
h2, .h2 {
    font-size: 1.5rem;
    font-weight: 300;
    color: $redwood-80;
    margin-bottom: 1.25rem;
}
h3, .h3 {
    font-size: 1.25rem;
    color: $red-80;
    margin-bottom: 1.125rem;
}
h4, .h4 {
    font-size: 1.125rem;
    color: $red-80;
    margin-bottom: 1rem;
}
h5, .h5 {
    font-size: 1.125rem;
    font-weight: 300;
    color: $redwood-80;
    margin-bottom: 1rem;
}
h6, .h6 {
    font-size: 1rem;
    color: $redwood-80;
    margin-bottom: 0.75rem;
}

p {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
}

// used for error messaging, and fine print
small {
    font-size: 0.875rem; // 14px
}


// base text link
a {
    color: $textLinkColorDefault;
    text-decoration: $textLinkDecorationDefault;

    &:not(.text-button, .p-menuitem-link) {
        &:hover,
        &:focus,
        &:active {
            color: $textLinkColorHover;
            text-decoration: $textLinkDecorationHover;
        }
    }
}

// label style for other text elements like <p>
// used as a label above radio button group, for example
.label {
    font-weight: 700;
    margin-bottom: 0.5rem;
}

// Font Awesome icons
.fa-icon::before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font: var(--fa-font-sharp-light); // default weight, can overwrite
    content: '\f2b4'; // FA logo icon FPO, overwrite 
    color: $red-60; // default color, overwrite
}

// definition list - used in merged donor info
dl {
    margin-top: 0;
    
    dt {
        font-weight: 700;
        margin-bottom: 0.25rem;

        &:not(:first-of-type) {
            margin-top: 1.5rem;
        }
    }

    dd {
        margin-left: 0;
        margin-bottom: 0.25rem;
    }
}
